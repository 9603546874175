import { AxiosResponse } from "axios";
import {
  ActiveTripActionT,
  AddExpenseResponse,
  TripActionAxiosResponse,
  DefaultTripActionT,
  TripDetailsAxiosResponse,
  TripFilter,
  TripAxiosResponse,
  AppLessQuery,
  AppLessTripActionT,
  ExpenseAPIPayload,
  ClaimSubmitAxiosResponse,
  ExportInvoiceTripsRequest,
  ReadyInvoiceAxiosResponse,
} from "@/stores/trip/types.ts";
import { HTTP_TRIP } from "@/services/enums/httpEnum.ts";
import { useAxios } from "@/services/axios";

/**
 * Fetches a list of trips depending on trip type.
 *
 * @param {TripFilter} payload - The filter criteria for the trips to be fetched.
 * @returns {Promise<AxiosResponse<TripAxiosResponse>>} - A Promise that resolves to the response from the server.
 */
export const fetchTripList = (
  payload: TripFilter
): Promise<AxiosResponse<TripAxiosResponse>> => {
  return useAxios.get(HTTP_TRIP.TRIP_LIST, { params: payload });
};

/**
 * Submits expense data for a trip.
 *
 * @param {ExpenseAPIPayload} payload - The expense data to be submitted, structured according to the `ExpenseAPIPayload` type.
 * @returns {Promise<AxiosResponse<AddExpenseResponse>>} A promise that resolves with the server's response, containing the result of the add expense operation.
 */
export const addExpensesApi = (
  payload: ExpenseAPIPayload
): Promise<AxiosResponse<AddExpenseResponse>> => {
  return useAxios.post(HTTP_TRIP.ADD_EXPENSES, payload);
};

/**
 * Fetches the details of a specific trip.
 *
 * @param {string} tripId - The ID of the trip to be fetched.
 * @returns {Promise<AxiosResponse<TripDetailsAxiosResponse>>} - A Promise that resolves to the response from the server containing the trip details.
 */
export const fetchTripDetails = (
  tripId: string
): Promise<AxiosResponse<TripDetailsAxiosResponse>> => {
  return useAxios.get(HTTP_TRIP.TRIP_DETAILS, {
    params: { trip_id: tripId },
  });
};

/**
 * Fetches the details of a specific trip for appless driver.
 *
 * @param {AppLessQuery} query - The query params to fetch appless trip deta.
 * @returns {Promise<AxiosResponse<TripDetailsAxiosResponse>>} - A Promise that resolves to the response from the server containing the trip details.
 */
export const fetchAppLessTripDetails = (
  query: AppLessQuery
): Promise<AxiosResponse<TripDetailsAxiosResponse>> => {
  return useAxios.get(HTTP_TRIP.APP_LESS_TRIP_DETAILS, {
    params: query,
  });
};

/**
 * Performs a request trip action.
 *
 * This function sends a POST request to the server with the specified trip action.
 * The server is expected to perform the action and return a response indicating the result of the action.
 *
 * @param {DefaultTripActionT} payload - The action to be performed on the trip.
 * @returns {Promise<AxiosResponse<TripActionAxiosResponse>>} - A Promise that resolves to the response from the server.
 */
/**
 * Performs a trip action.
 *
 * This function sends a POST request to the server with the specified trip action.
 * The server is expected to perform the action and return a response indicating the result of the action.
 *
 * @param {DefaultTripActionT | ActiveTripActionT} payload - The action to be performed on the trip.
 * @returns {Promise<AxiosResponse<TripActionAxiosResponse>>} - A Promise that resolves to the response from the server.
 */
export const tripAction = (
  payload: DefaultTripActionT | ActiveTripActionT
): Promise<AxiosResponse<TripActionAxiosResponse>> => {
  return useAxios.post(HTTP_TRIP.TRIP_ACTIONS, payload);
};
/**
 * Performs a trip action for invoice export
 *
 * This function sends a POST request to the server with the specified trip action.
 * The server is expected to perform the action and return a response indicating the result of the action.
 *
 * @param {DefaultTripActionT | ActiveTripActionT} payload - The action to be performed on the trip.
 * @returns {Promise<AxiosResponse<TripActionAxiosResponse>>} - A Promise that resolves to the response from the server.
 */
export const tripActionExportInvoice = (
  payload: ExportInvoiceTripsRequest
): Promise<AxiosResponse<TripActionAxiosResponse>> => {
  return useAxios.post(HTTP_TRIP.TRIP_ACTION_INVOICE_EXPORT, payload);
};

/**
 * Sends an OTP for appless driver.
 *
 * This function sends a GET request to the server with the specified driver id.
 * The server is expected to send an OTP to the driver and return a response indicating the result of the action.
 *
 * @param {string} driver_id - The id of the driver to whom the OTP is to be sent.
 * @returns {Promise<AxiosResponse<any>>} - A Promise that resolves to the response from the server.
 */
export const sendAppLessOTP = (
  driver_id: string
): Promise<AxiosResponse<any>> => {
  return useAxios.get(HTTP_TRIP.SEND_APP_LESS_OTP, { params: { driver_id } });
};

/**
 * Performs a trip action for appless driver.
 *
 * This function sends a POST request to the server with the specified trip action.
 * The server is expected to perform the action and return a response indicating the result of the action.
 *
 * @param {AppLessTripActionT} payload - The action to be performed on the trip.
 * @returns {Promise<AxiosResponse<TripActionAxiosResponse>>} - A Promise that resolves to the response from the server.
 */
export const appLessTripAction = (
  payload: AppLessTripActionT
): Promise<AxiosResponse<TripActionAxiosResponse>> => {
  return useAxios.post(HTTP_TRIP.APP_LESS_TRIP_ACTIONS, payload);
};

export const submitClaimToHost = (
  payload: DefaultTripActionT
): Promise<AxiosResponse<ClaimSubmitAxiosResponse>> => {
  return useAxios.post(HTTP_TRIP.SUBMIT_CLAIM, payload);
};

export const triggerReadyToInvoice = (
  payload: DefaultTripActionT
): Promise<AxiosResponse<ReadyInvoiceAxiosResponse>> => {
  return useAxios.post(HTTP_TRIP.TRIP_ACTIONS, payload);
};
