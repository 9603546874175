import { State } from "@/stores/vehicle/type.ts";
/*
 * define state here
 */
export default (): State => ({
  vehicleListResponse: { results: [], count: 0 },
  options: {
    vehicleTypes: [
      {
        value: "Coach",
        key: "coach",
      },
      {
        value: "Double decker",
        key: "double_decker",
      },
      {
        value: "Minibus",
        key: "minibus",
      },
      {
        value: "Midibus",
        key: "midibus",
      },
      {
        value: "Transit bus",
        key: "transit_bus",
      },
      {
        value: "Sedan",
        key: "sedan",
      },
      {
        value: "Service vehicle",
        key: "service_vehicle",
      },
    ],
    fuelTypes: [
      {
        value: "Diesel",
        key: "diesel",
      },
      {
        value: "Petrol",
        key: "petrol",
      },
      {
        value: "EL",
        key: "el",
      },
      {
        value: "Hydrogen",
        key: "hydrogen",
      },
      {
        value: "HVO",
        key: "hvo",
      },
      {
        value: "Other",
        key: "other",
      },
    ],
    euroClasses: [
      {
        value: "Euro 4",
        key: "euro_4",
      },
      {
        value: "Euro 5",
        key: "euro_5",
      },
      {
        value: "Euro 6",
        key: "euro_6",
      },
      {
        value: "EL",
        key: "el",
      },
    ],
    luggageOptions: [
      {
        value: "No luggage",
        key: "no",
      },
      {
        value: "Hand luggage only",
        key: "hand_only",
      },
      {
        value: "1 bag per traveller",
        key: "one_bag",
      },
      {
        value: "More than 1 bag per traveller",
        key: "more_bags",
      },
    ],
  },
});
