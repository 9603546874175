import { format } from "date-fns";
import { convertUTCtoLocaleReadable } from "@/utils/dateFormat.util.ts";
import { locale } from "@/locales";
/**
 * Convert date  to desired format
 * @param {Date} date
 * @param dateTimeFormat
 * @return {string} formatted date
 */
export const formatDateTime = (
  date: Date,
  dateTimeFormat = "dd.MM.yyyy HH:mm"
): string => {
  if (!date) return "";
  return format(date, dateTimeFormat);
};

export const timeFormatToShow = (dateTime: string) => {
  if (!dateTime) return "";
  return convertUTCtoLocaleReadable(
    dateTime,
    locale === "en" ? "en-US" : "no-NO"
  );
};
/**
 * Removed GMT and beyond for make sure its not manipulating timezone while new Date
 * @param {string} dateString -string which need to be removed
 * @returns {string} formatted str
 */
export const removeGMTAndBeyond = (dateString) => {
  return dateString.replace(/GMT.*/, "").trim();
};

export const isoFormatDateTime = (date: string) => {
  const converted = new Date(removeGMTAndBeyond(date));

  return `${converted.getFullYear()}-${setLeadingZero(
    converted.getMonth() + 1
  )}-${setLeadingZero(converted.getDate())}T${setLeadingZero(
    converted.getHours()
  )}:${setLeadingZero(converted.getMinutes())}:${setLeadingZero(
    converted.getSeconds()
  )}Z`;
};

export const setLeadingZero = (value: number) => {
  return value < 10 ? `0${value}` : value;
};

/**
 * Removed Z from string make sure its not manipulating timezone while new Date
 * @param {string} dateTime -string which need to be removed
 * @returns {string} formatted str
 */
export const removeZforISOString = (dateTime: string): string => {
  return dateTime && dateTime.toString().endsWith("Z")
    ? dateTime.replace("Z", "")
    : dateTime;
};

export const removeTZforISOString = (dateTime: string): string => {
  const modified = removeZforISOString(dateTime);
  const withoutMilliseconds = modified.split(".")[0];
  return withoutMilliseconds && withoutMilliseconds.toString().indexOf("T")
    ? withoutMilliseconds.replace("T", " ")
    : withoutMilliseconds;
};

export const secToReadableTime = (totalSec: number) => {
  if (!totalSec) return "";
  const totalMinutes = totalSec / 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  return `${hours}h ${minutes}m`;
};

export const dateTimeToBackendFormat = (dateTime: string | undefined) => {
  return dateTime && removeTZforISOString(isoFormatDateTime(dateTime));
};
