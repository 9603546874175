import { Trip } from "../../../stores/trip/types";

export const mockTripData: Trip = {
  gen_tid: "1",
  trip_id: "1",
  pax: 1,
  trip_duration: 1,
  price: 1,
  start_datetime: "2021-07-29T08:00:00.000Z",
  trip_status: "requested",
  type: "one_way",
  via_point_count: 1,
  from_address: "test",
  to_address: "test",
  host_company: {
    domain: "test",
    id: "1",
    name: "test",
    org_number: "test",
    type: "test",
  },
  request_id: "1",
  driver: {
    id: "1",
    phone_number: "test",
    first_name: "test",
    last_name: "test",
  },
  vehicle: {
    id: "1",
    name: "test",
    vehicle_type: "test",
    registration_number: "test",
  },
  attachment_1: "test",
  attachment_2: "test",
  finalize: false,
};
