// src/mocks/handlers.js
import { rest } from "msw";
import { customResponse } from "../../../mocks/customResponse";
import { HTTP_CUSTOMER_PORTAL } from "../../enums/httpEnum";
import { mockTripData } from "../../../tests/mockData/trips/tripList";

export const mockTripHandlers = [
  /**
   * Handler for fetch mock mock trip list data
   */
  rest.get(HTTP_CUSTOMER_PORTAL.FETCH_TRIP_LIST, (_req, _, ctx) => {
    return customResponse(
      //ctx.set("company-time", new Date().getTime() + ""),
      ctx.status(200),
      ctx.json({ data: mockTripData })
    );
  }),
];
