import {
  Vehicle,
  VehiclePayload,
  VehicleListResponse,
  StoreContext,
  VehicleResponse,
} from "@/stores/vehicle/type.ts";
import {
  addVehicle,
  editVehicle,
  fetchVehicleDetails,
  fetchVehicleList,
} from "@/services/api/vehicle/vehicleApi.ts";
import { RESPONSE_TYPE } from "@/services/enums/httpEnum.ts";

export default {
  /**
   * Creates a vehicle.
   * @param vehicle - The vehicle containing the necessary data to create the vehicle.
   * @returns A Promise that resolves to an object representing the created vehicle.
   * @throws If there is an API error during the process.
   */
  async createVehicle(vehicle: Vehicle): Promise<VehicleResponse> {
    try {
      const { data: response } = await addVehicle(vehicle);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /**
   * Get vehicle list.
   * @param payload - The request body.
   * @returns A Promise that resolves to an object representing vehicle list.
   * @throws If there is an API error during the process.
   */
  async getVehicleList(
    this: StoreContext,
    payload: VehiclePayload,
    virtualScroll: boolean = false
  ): Promise<VehicleListResponse> {
    try {
      const { data: response } = await fetchVehicleList(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }

      this.$state.vehicleListResponse.count = response.data.count;

      if (virtualScroll && (payload.skip as number) > 0) {
        this.$state.vehicleListResponse.results = [
          ...this.$state.vehicleListResponse.results,
          ...response.data.results,
        ];
      } else {
        this.$state.vehicleListResponse.results = response.data.results;
      }

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Get vehicle details.
   * @param payload - The request body.
   * @returns A Promise that resolves to an object representing vehicle list.
   * @throws If there is an API error during the process.
   */
  async getVehicleDetails(payload: VehiclePayload): Promise<VehicleResponse> {
    try {
      const { data: response } = await fetchVehicleDetails(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Update a vehicle.
   * @param vehicle - The vehicle containing the necessary data to update the vehicle.
   * @returns A Promise that resolves to an object representing the created vehicle.
   * @throws If there is an API error during the process.
   */
  async updateVehicle(vehicle: Vehicle): Promise<VehicleResponse> {
    try {
      const { data: response } = await editVehicle(vehicle);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
