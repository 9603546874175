import {
  NotificationSettings,
  UpdateNotificationT,
} from "@/stores/notification/type.ts";
import {
  fetchNotificationSettings,
  updateNotificationSettings,
} from "@/services/api/notification/notificationApi.ts";
import { RESPONSE_TYPE } from "@/services/enums/httpEnum.ts";

export default {
  async getNotificationSettings(): Promise<NotificationSettings> {
    try {
      const { data: response } = await fetchNotificationSettings();
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async updateNotificationSettings(
    payload: UpdateNotificationT
  ): Promise<NotificationSettings> {
    try {
      const { data: response } = await updateNotificationSettings(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
