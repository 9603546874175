import {
  CompanyAffiliationInfo,
  CompanyInfo,
  CreateProfilePrefilledDataCreatePassword,
  CreateProfilePrefilledDataInvitationAndVerify,
  State,
} from "@/stores/auth/types.ts";

/**
 * Returns the pre-filled data for creating a profile.
 * @param {State} state - The state object.
 * @returns {CreateProfilePrefilledData} The pre-filled data for creating a profile.
 */
export default {
  profilePrefilledDataInvitationAndVerify: (
    state: State
  ): CreateProfilePrefilledDataInvitationAndVerify => {
    const { admin_user, company_affiliation_info } = state.verifyUserResponse;
    const { user_id, email, phone } = admin_user;
    const { host_company, target_company, terms_and_conditions, _id } =
      company_affiliation_info as CompanyAffiliationInfo;

    return {
      user_id,
      email,
      phone,
      host_company_name: host_company?.name,
      target_company_name: target_company.name,
      terms_and_conditions,
      company_affiliation_id: _id,
    };
  },
  profilePrefilledDataPasswordCreate: (
    state: State
  ): CreateProfilePrefilledDataCreatePassword => {
    const { admin_user, company_info } = state.verifyUserResponse;
    const { user_id, email, phone } = admin_user;
    const { name } = company_info as CompanyInfo;

    return {
      user_id,
      email,
      phone,
      host_company_name: name,
    };
  },
};
