import { useAxios } from "@/services/axios";
import { AxiosResponse } from "axios";
import { HTTP_VEHICLE } from "@/services/enums/httpEnum.ts";
import {
  Vehicle,
  VehiclePayload,
  VehicleListResponse,
  VehicleResponse,
} from "@/stores/vehicle/type.ts";

/**
 * add vehicle
 *  @param payload for vehicle add
 *  @returns A Promise that resolves to the response from the server.
 */

export const addVehicle = (
  payload: Vehicle
): Promise<AxiosResponse<VehicleResponse>> => {
  return useAxios.post(HTTP_VEHICLE.VEHICLES, payload);
};

/**
 * fetch vehicle list
 *  @param payload for vehicle list
 *  @returns A Promise that resolves to the response from the server.
 */

export const fetchVehicleList = (
  payload: VehiclePayload
): Promise<AxiosResponse<VehicleListResponse>> => {
  return useAxios.get(HTTP_VEHICLE.VEHICLES, {
    params: {
      ...payload,
    },
  });
};

/**
 * fetch vehicle details
 *  @param payload for vehicle details
 *  @returns A Promise that resolves to the response from the server.
 */

export const fetchVehicleDetails = (
  payload: VehiclePayload
): Promise<AxiosResponse<VehicleResponse>> => {
  return useAxios.get(HTTP_VEHICLE.VEHICLES, {
    params: {
      ...payload,
    },
  });
};

/**
 * edit vehicle
 *  @param payload for vehicle edit
 *  @returns A Promise that resolves to the response from the server.
 */

export const editVehicle = (
  payload: Vehicle
): Promise<AxiosResponse<VehicleResponse>> => {
  return useAxios.patch(HTTP_VEHICLE.VEHICLES, payload);
};
