import { User } from "types/auth/user.type";
import {CompanyUserDetailsT, EmployeeT, State} from "./types";

const countryCodeToTimezone = {
  GB: "Europe/London",
  NO: "Europe/Oslo",
  SE: "Europe/Oslo",
  DK: "Europe/Oslo",
};

export default {
  /**
   * Retrieves the user information from the state.
   *
   * @param {State} state - The Vuex state object.
   * @returns {User} The user object.
   */
  getUserInfo: (state: State): User => {
    return state.data;
  },

  /**
   * Retrieves the full name of the user.
   *
   * @param {State} state - The Vuex state object.
   * @returns {string} The full name of the user.
   */
  getFullname: (state: State): string => {
    const companyUserinfo = state.companyUserDetails.user_info;
    return `${companyUserinfo.first_name} ${companyUserinfo.last_name}`;
  },

  /**
   * Retrieves the authentication status of the user.
   *
   * @param {State} state - The Vuex state object.
   * @returns {boolean} The authentication status of the user.
   */
  getAuthenticationInfo: (state: State): boolean => {
    return state.isAuthenticated;
  },

  /**
   * Retrieves the company user details information.
   *
   * @param {State} state - The Vuex state object.
   * @returns {CompanyUserDetailsT} The company user details object.
   */
  getCompanyUserDetailsInfo: (state: State): CompanyUserDetailsT => {
    return state.companyUserDetails;
  },

  getRoles: (state: State): string[] => {
    return state.companyUserDetails.user_info.roles;
  },

  /**
   * Retrieves the user's company ID.
   *
   * @param {State} state - The Vuex state object.
   * @returns {string} The user's company ID.
   */
  getUserCompanyId: (state: State): string => {
    return state.companyUserDetails.company_info.company_id;
  },
  /**
   *
   * @param {state} state - state of user store
   * @returns {EmployeeT[]} - list of employees
   */
  getEmployeeList: (state: State): EmployeeT[] => {
    return state.userListResponse.results;
  },

  /**
   * Retrieves the user's timezone.
   *
   * @param {State} state - The Vuex state object.
   * @returns {string} The user's timezone.
   */

  getUserTimeZone: (state: State): string => {
    return countryCodeToTimezone[state.companyUserDetails?.company_info?.country_code?.toUpperCase()] || "Europe/Oslo";
  }

};
