export enum URL_AUTH {
  SIGN_IN = "signin",
  FORGOT_PASSWORD = "forgot-password",
  CREATE_PROFILE = "create-profile",
  EXPIRED_INVITATION = "expired-invitation",
  PASSWORD_RESET = "password-reset",
  VERIFY_AFFILIATION = "verify-affiliation",
  CREATE_EMPLOYEE_PASSWORD = "create-employee-password",
  APPLESS_VERIFICATION = "appless-verification",
}
