<template>
  <BaseLoader
    :active="loader.getLoadingStatus.isLoading"
    spinner-position="justify-content-center position-fixed"
    loader-icon="/logo.svg"
    :text="loaderText"
    text-class="ship-gray"
    overlay-background-color="overlay-primary-background"
  />
</template>

<script setup lang="ts">
import BaseLoader from "@busgroup/vue3-base-loader";
import { useLoaderStore } from "@/stores";
const loader = useLoaderStore();
defineProps({
  loaderText: {
    type: String,
    default: "",
  },
});
</script>
