import { defineStore } from "pinia";

const useThemeStore = defineStore("theme", {
  state: () => ({
    screenSize: 0,
  }),
  getters: {
    isSmallScreen(state) {
      return state.screenSize < 576;
    },
  },
  actions: {
    changeScreenSize(payload: number) {
      this.screenSize = payload;
    },
  },
});

export default useThemeStore;
