import { ROLE } from "@/services/enums/commonEnum";
import { URL_AUTH } from "@/services/enums/routeEnum";
import { useAuthStore, useUserStore } from "@/stores";
import { routerResolve } from "@/utils/route.utils";
import { computed, watchEffect } from "vue";

export const auth = {
  isAuthenticated: computed(() => useUserStore().getAuthenticationInfo),
  isCognitoFetchingExecuting: computed(
    () => useUserStore().$state.isFetchingCognitoInfoExecuting
  ),
  isVerifyAPIExecuting: computed(
    () => useAuthStore().$state.verifyAPIExecuting
  ),
  isVerificationTypeAvailable: computed(
    () => useAuthStore().$state.verifyUserResponse.verification_type
  ),
  isCompanyApiExecuting: computed(
    () => useUserStore().$state.isCompanyApiExecuting
  ),
  roles: computed(() => useUserStore().getRoles),
};

const verifyAndRedirect = (condition, path) => {
  return condition ? { path } : null;
};

export const manageLoggedUserRedirectionGuard = (to, from, next) => {
  const { isAuthenticated, isCognitoFetchingExecuting } = auth;
  const shouldRedirect = () => isAuthenticated.value;
  if (!isCognitoFetchingExecuting.value) {
    return next(verifyAndRedirect(shouldRedirect(), "dashboard"));
  }

  watchEffect(() => {
    if (!isCognitoFetchingExecuting.value) {
      return next(verifyAndRedirect(shouldRedirect(), "dashboard"));
    }
  });
};

export const notAuthenticatedGuard = (to, from, next) => {
  const { isCognitoFetchingExecuting, isAuthenticated } = auth;
  const shouldRedirect = () =>
    !isAuthenticated.value && to.path !== `/auth/${URL_AUTH.SIGN_IN}`;
  if (!isCognitoFetchingExecuting.value) {
    return next(
      verifyAndRedirect(shouldRedirect(), `/auth/${URL_AUTH.SIGN_IN}`)
    );
  }
  watchEffect(() => {
    if (!isCognitoFetchingExecuting.value) {
      return next(
        verifyAndRedirect(shouldRedirect(), `/auth/${URL_AUTH.SIGN_IN}`)
      );
    }
  });
};

export const checkVerificationProcessGuard = (to, from, next) => {
  const { isVerificationTypeAvailable, isVerifyAPIExecuting } = auth;
  const isVerificationPath =
    to.path === `/auth/${URL_AUTH.VERIFY_AFFILIATION}` ||
    to.path === `/auth/${URL_AUTH.CREATE_EMPLOYEE_PASSWORD}` ||
    to.path === `/auth/${URL_AUTH.CREATE_PROFILE}`;
  const shouldRedirect = () =>
    isVerificationPath && !isVerificationTypeAvailable.value;
  if (!isVerifyAPIExecuting.value) {
    return next(
      verifyAndRedirect(shouldRedirect(), `/auth/${URL_AUTH.SIGN_IN}`)
    );
  }
  watchEffect(() => {
    if (!isVerifyAPIExecuting.value) {
      return next(
        verifyAndRedirect(shouldRedirect(), `/auth/${URL_AUTH.SIGN_IN}`)
      );
    }
  });
};

export const RoleCheck = (to, from, next) => {
  const { isAuthenticated, isCompanyApiExecuting, roles } = auth;

  const shouldRedirect = () => isAuthenticated.value;

  const go = () => {
    if (roles.value.includes(ROLE.DRIVER) && roles.value.length === 1) {
      return next(
        verifyAndRedirect(
          shouldRedirect() && to.path !== "/access-panel",
          routerResolve("access_panel")
        )
      );
    } else {
      return next(verifyAndRedirect(false, ""));
    }
  };

  if (!isCompanyApiExecuting.value) {
    go();
  }

  watchEffect(() => {
    if (!isCompanyApiExecuting.value) {
      go();
    }
  });
};
