import { showLoader } from "@/utils/utils.ts";
import { useUserStore } from "@/stores";
export const useAppInitiate = () => {
  const user = useUserStore();
  const getHostCompanyData = async () => {
    //TODO:put status on enum at some point
    try {
      const payload = {
        requester_company_id:
          user.$state.companyUserDetails.company_info.company_id,
        sort_field: "name",
        sort_order: "asc",
        limit: 20,
        status: "accepted",
      };
      await user.fetchHostCompanyData({
        ...payload,
        skip: 0,
        search: "",
      });
    } catch (err) {
    } finally {
      showLoader(false);
    }
  };

  return {
    getHostCompanyData,
  };
};
