import {
  TripFilter,
  StoreContext,
  TripDetails,
  DefaultTripActionT,
  TripActionAxiosResponse,
  ActiveTripActionT,
  TripResponseT,
  AppLessQuery,
  AppLessTripActionT,
  ExpenseAPIPayload,
  ClaimSubmitAxiosResponse,
  ReadyInvoiceAxiosResponse,
  ExportInvoiceTripsRequest,
} from "@/stores/trip/types.ts";
import { RESPONSE_TYPE } from "@/services/enums/httpEnum.ts";
import {
  fetchTripList,
  addExpensesApi,
  fetchTripDetails,
  tripAction,
  fetchAppLessTripDetails,
  sendAppLessOTP,
  appLessTripAction,
  submitClaimToHost,
  triggerReadyToInvoice,
  tripActionExportInvoice,
} from "@/services/api/trips/tripApi.ts";
import { CommonResponseGateWay } from "types/common";
import { downloadBase64File, getCurrentDateTimeForFile } from "@/utils/utils";

export default {
  /**
   * Get trip list.
   * @param payload - The request body.
   * @returns A Promise that resolves to an object representing requested trip list.
   * @throws If there is an API error during the process.
   */
  async getTripList(
    this: StoreContext,
    payload: TripFilter
  ): Promise<TripResponseT> {
    if (this.$state.contextType !== payload.trip_type) {
      this.$reset();
      this.$state.contextType = payload.trip_type;
    }
    try {
      const { data: response } = await fetchTripList(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      this.$state.tripListResponse = response.data;
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Get trip details.
   * @param tripId - The trip ID.
   * @param action
   * @returns A Promise that resolves to an object representing trip details.
   * @throws If there is an API error during the process.
   */

  async getTripDetails(
    this: StoreContext,
    tripId: string,
    action: string
  ): Promise<TripDetails> {
    try {
      const { data: response } = await fetchTripDetails(tripId);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Get trip details for appless driver.
   * @param {AppLessQuery} query - Query params for appless driver.
   * @returns A Promise that resolves to an object representing trip details.
   * @throws If there is an API error during the process.
   */
  async dispatchAppLessTripDetails(
    this: StoreContext,
    query: AppLessQuery
  ): Promise<TripDetails> {
    try {
      const { data: response } = await fetchAppLessTripDetails(query);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Send OTP to appless driver.
   * @param {string} driverId -driver id to send OTP.
   * @returns A Promise that resolves to an object representing trip details.
   * @throws If there is an API error during the process.
   */
  async dispatchAppLessOTPSend(
    this: StoreContext,
    driverId: string
  ): Promise<CommonResponseGateWay> {
    try {
      const { data: response } = await sendAppLessOTP(driverId);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async addExpenses(
    this: StoreContext,
    payload: ExpenseAPIPayload
  ): Promise<object> {
    try {
      const { data: response } = await addExpensesApi(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * handle request trip actions.
   * @param payload - The request body.
   * @returns A Promise that resolves to an object representing trip actions.
   * @throws If there is an API error during the process.
   */

  async triggerReqTripAction(
    this: StoreContext,
    payload: DefaultTripActionT
  ): Promise<TripActionAxiosResponse> {
    try {
      const { data: response } = await tripAction(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * handle active trip actions.
   * @param payload - The request body.
   * @returns A Promise that resolves to an object representing trip actions.
   * @throws If there is an API error during the process.
   */
  async triggerActiveTripAction(
    this: StoreContext,
    payload: ActiveTripActionT
  ): Promise<TripActionAxiosResponse> {
    try {
      const { data: response } = await tripAction(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * handle appless trip actions.
   * @param payload - The request body.
   * @returns A Promise that resolves to an object representing trip actions.
   * @throws If there is an API error during the process.
   */
  async triggerAppLessTripAction(
    this: StoreContext,
    payload: AppLessTripActionT
  ): Promise<TripActionAxiosResponse> {
    try {
      const { data: response } = await appLessTripAction(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Submits a claim to the host.
   *
   * @param {StoreContext} this - The store context in which this method is called.
   * @param {ClaimSubmitPayload} payload - The payload required for submitting the claim.
   * @returns {Promise<ClaimSubmitAxiosResponse>} - A promise that resolves to the claim submission response.
   * @throws {Error} - Throws an error if the API call fails.
   */
  async submitClaim(
    this: StoreContext,
    payload: DefaultTripActionT
  ): Promise<ClaimSubmitAxiosResponse> {
    try {
      const { data: response } = await submitClaimToHost(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async setReadyToInvoice(
    this: StoreContext,
    payload: DefaultTripActionT
  ): Promise<ReadyInvoiceAxiosResponse> {
    try {
      const { data: response } = await triggerReadyToInvoice(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /**
   * handle invoice trip actions.
   * @param payload - The request body.
   * @returns A Promise that resolves to an object representing trip actions.
   * @throws If there is an API error during the process.
   */
  async triggerInvoiceTripAction(
    this: StoreContext,
    payload: ExportInvoiceTripsRequest
  ): Promise<TripActionAxiosResponse> {
    try {
      const { data: response } = await tripActionExportInvoice(payload);
      const responseString: string = String(response);
      downloadBase64File(
        responseString,
        `invoice-${getCurrentDateTimeForFile()}.xlsx`,
        "xlsx"
      );
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
