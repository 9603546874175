import { State } from "./types";
/*
 * define state here
 */
export default (): State => ({
  data: {
    username: "",
    id: "",
    attributes: {
      sub: "",
      identities: "",
      email_verified: false,
      name: "",
      phone_number_verified: false,
      isProfileComplete: "",
      email: "",
      photo: "",
    },
  },
  isAuthenticated: false,
  shouldAuthenticationBegin: false,
  isFetchingCognitoInfoExecuting: true,
  isCompanyApiExecuting: true,
  assignedRole: "",
  companyUserDetails: {
    company_info: {
      company_id: "",
      name: "",
      org_number: "",
      country_code: "",
      status: "",
    },
    user_info: {
      user_id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      roles: [""],
    },
  },
  hostCompanyData: {
    count: 0,
    results: [],
  },
  userListResponse: {
    count: 0,
    results: [],
  },
});
