import { State } from "@/stores/notification/type.ts";

export default (): State => ({
  notificationSettings: {
    network_trip_request: false,
    pre_confirmed_trip: false,
    subcontractor_trip_update: false,
    price_change: false,
    network_trip_cancellation: false,
    claim_status: false,
  },
});
