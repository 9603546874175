import { defineStore } from "pinia";
import state from "./vehicle.state";
import actions from "./vehicle.actions";
import getters from "./vehicle.getters";

const useVehicleStore = defineStore("vehicle", {
  state,
  actions,
  getters,
});

export default useVehicleStore;
