import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import { useLoaderStore } from "../stores";
import { scrollToHeight } from "../utils/route.utils.ts";
import authRoutes from "../router/modules/auth.ts";
import {
  notAuthenticatedGuard,
  manageLoggedUserRedirectionGuard,
  RoleCheck,
} from "./guard/RouteGuard.ts";

/**
 * Generates a Vue Router Route Record Raw object.
 *
 * @param {string} path - The URL path for the route.
 * @param {string} name - The name of the route.
 * @param {Component} component - The Vue component associated with the route.
 * @param {Function[]} [routeGuards=[]] - An optional array of route guard functions.
 * @returns {RouteRecordRaw} The generated Vue Router Route Record Raw object.
 */
const generateRecordRaw = (
  path,
  name,
  component,
  routeGuards = []
): RouteRecordRaw => {
  return {
    path,
    beforeEnter: [...routeGuards],
    name,
    component,
  };
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "dashboard",
    beforeEnter: [notAuthenticatedGuard, RoleCheck],
    redirect: "/request-trip",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      generateRecordRaw(
        "/request-trip",
        "request_trip",
        () => import("../views/trip/RequestTrip.vue")
      ),

      generateRecordRaw(
        "/active-trip",
        "active_trip",
        () => import("../views/trip/ActiveTrip.vue")
      ),
      generateRecordRaw(
        "/closed-trip",
        "closed_trips",
        () => import("../views/trip/ClosedTrips.vue")
      ),
      generateRecordRaw(
        "/trip-details/:tag",
        "trip_details",
        () => import("../views/trip/TripDetailsWrapper.vue")
      ),
      generateRecordRaw(
        "/invoice-list",
        "invoice_list",
        () => import("../views/invoice/InvoiceList.vue")
      ),

      {
        path: "/settings",
        name: "settings",
        redirect: "/settings/organization",
        component: () => import("../views/setting/Settings.vue"),
        children: [
          {
            name: "organization",
            path: "organization",
            component: () =>
              import("@/components/modules/settings/Organization.vue"),
          },
          {
            name: "employee",
            path: "employee",
            component: () =>
              import("@/components/modules/settings/employee/Employee.vue"),
          },
          {
            name: "vehicle",
            path: "vehicle",
            component: () =>
              import("@/components/modules/settings/vehicle/Vehicle.vue"),
          },
          {
            name: "notification",
            path: "notification",
            component: () =>
              import("@/components/modules/settings/Notification.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/appless",
    component: () => import("../layouts/AppLessLayout.vue"),

    children: [
      {
        path: "/appless-verification/:tag",
        name: "appless_verification",
        component: () => import("../views/trip/VerifyAppless.vue"),
      },
      generateRecordRaw(
        "/appless-trip-details/:tag",
        "appless_trip_details",
        () => import("../views/trip/TripDetailsWrapper.vue")
      ),
    ],
  },
  {
    path: "/",
    name: "authentication",
    redirect: "/auth/signin",
    beforeEnter: [manageLoggedUserRedirectionGuard],
    component: () => import("../layouts/AuthLayout.vue"),
    children: [...authRoutes],
  },
  {
    path: "/invitation-link-verification",
    name: "invitation_link_verification",
    component: () => import("../views/auth/InvitationVerification.vue"),
  },
  {
    path: "/access-panel",
    name: "access_panel",
    component: () => import("../views/access-panel.vue"),
  },
  {
    path: "/redirect/:tag",
    name: "error",
    component: () => import("../views/RedirectionView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/RedirectionView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

/**
 * @description This is a global guard that will run before every route change
 */
router.beforeEach(async (to, from, next) => {
  if (to.path !== from.path) {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    scrollToHeight(-1);
  }

  /*if (to.path.includes("auth/")) {
    const alreadyLogin = ref(localStorage.getItem("user-info"));
    if (alreadyLogin.value) {
      next({ name: "dashboard" });
    }
  }

  const cantAccess = ref(
    !to.meta?.public && !localStorage.getItem("user-info")
  );
  if (cantAccess.value) {
    // Need to sign out here
    if (to.name) {
      localStorage.setItem("intended", <string>to.name);
    }
  }*/

  next();
});

router.afterEach((to, from) => {
  if (to.path !== from.path) {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: false });
  }
});
export default router;
