import { App } from "vue";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import TabMenu from "primevue/tabmenu";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";

type ComponentDictionary = {
  [key: string]: any;
};

const componentDictionary: ComponentDictionary = {
  Menubar,
  Menu,
  TabMenu,
  Dialog,
  Dropdown,
};

export default (app: App) => {
  Object.keys(componentDictionary).forEach((key) => {
    app.component(key, componentDictionary[key]);
  });
};
