import {
  App,
  AsyncComponentLoader,
  Component,
  ComputedOptions,
  MethodOptions,
  defineAsyncComponent,
} from "vue";

// This is for Automatic Global Registration of Base Components
// See main.ts, where this is applied

const modules = import.meta.glob("./**/*.vue");
// const modules = import.meta.glob("@/components/common/testComponent/*.vue");
/**
 * This function is responsible for component registration.
 *
 * @param {App<Element>}  app - App instance
 */
const register = (app: App<Element>): void => {
  for (const path in modules) {
    const componentName = path.split("/").at(-1)?.split(".")[0] as string;
    app.component(
      componentName,
      defineAsyncComponent(
        modules[path] as AsyncComponentLoader<
          Component<any, any, any, ComputedOptions, MethodOptions>
        >
      )
    );
  }
};
export default {
  register,
};
