import { useAxios } from "@/services/axios";
import { AxiosResponse } from "axios";
import {
  NotificationSettingsResponse,
  UpdateNotificationT,
} from "@/stores/notification/type.ts";
import { HTTP_NOTIFICATION } from "@/services/enums/httpEnum.ts";

export const fetchNotificationSettings = (): Promise<
  AxiosResponse<NotificationSettingsResponse>
> => {
  return useAxios.get(HTTP_NOTIFICATION.NOTIFICATION_SETTINGS);
};

export const updateNotificationSettings = (
  payload: UpdateNotificationT
): Promise<AxiosResponse<NotificationSettingsResponse>> => {
  return useAxios.post(HTTP_NOTIFICATION.NOTIFICATION_SETTINGS, payload);
};
