import { DateTimeFormatOptions } from "@intlify/core-base";
import {computed} from "vue";
import {useUserStore} from "@/stores";

const time_options: DateTimeFormatOptions = {
  day: "numeric",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  weekday: "long",
};

const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, "0");
};

export const formatDate = (date: Date, join = "-"): string => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join(join);
};

/**
 * Converts a UTC date and time string to a localized date and time string.
 * @param dateTime - The UTC date and time string to convert.
 * @param locale - The locale to use for formatting the date and time. Defaults to "no-No".
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getTimeZones}
 * @returns The localized date and time string.
 */
export const convertUTCtoLocaleReadable = (
  dateTime: string,
  locale = "no-No"
): string => {
  if (!dateTime) return "";
  const user = useUserStore();
  const timezone = computed(()=> user.getUserTimeZone);

  /**
   * UTC time string
   * @example
   * const utcTimeString = "2023-08-09T12:00:00Z"; // Replace this with your UTC time string
   */
  const utcTimeString = dateTime + "Z";
  /**
   * Create a Date object from the UTC time string
   */
  const utcDate = new Date(utcTimeString);

  const options = {
    timeZone:  timezone.value,
  };

  const localFormatter = new Intl.DateTimeFormat(locale, {
    ...time_options,
    ...options,
  });

  const localFormatter2 = new Intl.DateTimeFormat("no-No", {
    ...time_options,
    ...options,
  });

  /**
   * Format the UTC Date object in the local time zone
   */
  const localTimeString = localFormatter.format(utcDate).split(/[ ,]+/);

  const localTimeString2 = localFormatter2.format(utcDate).split(" ");

  return `${localTimeString[0]}, ${localTimeString2[1]} ${localTimeString2[2]}`;
};

/**
 * Converts a UTC date and time to local time and then localized in oslo date and time string.
 * @param {string | undefined} dateTime - The UTC date and time string to convert.
 * @param {string} locale - The locale to use for formatting the date and time. Defaults to "no-No".
 * @returns The localized date and time string.
 */
export const utcToLocaleDateTime = (
  dateTime: string | undefined,
  locale = "no-No"
): string => {
  if (!dateTime) return "";
  const utcTimeString = dateTime + "Z";
  const utcDate = new Date(utcTimeString);
  const user = useUserStore();
  const timezone = computed(()=> user.getUserTimeZone);

  const localFormatterForDate = new Intl.DateTimeFormat("en-US", {
    dateStyle: "short",
    timeStyle: "short",
    hour12: false,
    timeZone: timezone.value,
  });

  const localFormatterForTime = new Intl.DateTimeFormat("no-NO", {
    dateStyle: "short",
    timeStyle: "long",
    hour12: false,
    timeZone: timezone.value,
  });

  const date = localFormatterForDate.format(utcDate).split(/[ ,]+/)[0];
  const time = localFormatterForTime.format(utcDate).split(/[ ,]+/)[1];
  return `${date} ${time}`;
};

/**
 * Converts a UTC date and time to local Oslo date and time if conversion needed otherwise return oslo current time by default
 * @param isConversionNeeded - A boolean indicating whether conversion is needed. Default is false.
 * @param dateTime - The UTC date and time to convert.
 * @returns The converted local Oslo date and time.
 */
export const utcToLocalDateTimeV2 = (dateTime = "") => {
  let now;
  if (dateTime) {
    now = new Date(dateTime);
  } else {
    now = new Date();
  }
  // Get current date and time in UTC

  const user = useUserStore();
  const timezone = computed(()=> user.getUserTimeZone);

  // Convert to UTC string
  const utcTime = now.toISOString();

  console.log("UTC time: ", utcTime);

  // Convert UTC time to Oslo time
  const osloTime = new Date(utcTime).toLocaleString("en-GB", {
    timeZone: timezone.value,
  });
  const [day, month, yearTime] = osloTime.split("/");
  const [year, time] = yearTime.split(", ");
  return `${year}-${month}-${day} ${time}`;
};

/**
 * Checks if a given UTC timestamp has expired compared to the current time in a specified timezone.
 * @param {string} utcTimestamp The UTC timestamp string to compare (format: 'yyyy-MM-ddTHH:mm:ssZ').
 * @returns {boolean} True if the given timestamp has expired; false otherwise.
 */
export const hasExpiredInTimezone = (
  utcTimestamp: string
) => {
  if (!utcTimestamp) return "";
  // Ensure utcTimestamp ends with 'Z'
  if (!utcTimestamp.endsWith("Z")) {
    utcTimestamp += "Z";
  }

  const user = useUserStore();
  const timezone = computed(()=> user.getUserTimeZone);

  // Convert UTC timestamp to Date object
  const givenDate = new Date(utcTimestamp);

  // Create DateTimeFormat object for the specified timezone
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
    timeZone: timezone.value,
    hour12: false, // Use 24-hour format
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  // Format given date and current date in the specified timezone
  const formattedGivenDate = dateTimeFormat.format(givenDate);
  const currentDateTime = new Date();
  const formattedCurrentDate = dateTimeFormat.format(currentDateTime);
  // Compare formatted dates
  return formattedGivenDate < formattedCurrentDate;
};
