import { State } from "./types";

export default {
  /*
   * getting the loader
   */
  getLoadingStatus(state: State): State {
    return state;
  },
};
