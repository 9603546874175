import { checkVerificationProcessGuard } from "../guard/RouteGuard";

const authRoutes = [
  {
    path: "/auth/:tag",
    name: "auth",
    beforeEnter: [checkVerificationProcessGuard],
    meta: { public: true },
    component: () => import("../../views/auth/index.vue"),
  },
];
export default authRoutes;
