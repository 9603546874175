import { signIn, getUser, signOut } from "@/services/auth/auth.service";
import { showToast } from "@/services/toast/toast.service";
import useLoaderStore from "../loader/loader.store";
import {
  CompanyUserDetailsT,
  StoreContext,
  EmployeeDataT,
  EmployeeT,
  HostCompaniesData,
  HostCompanyFilter,
} from "./types";
import { UserSignIn } from "@/components/modules/auth/types";
import { GeneralError } from "types/errors/errors.type";
import { localStorageResetAction } from "@/localStorage/localStorage";
import {
  userManagement,
  fetchCompanyUserDetails,
} from "@/services/api/auth/authApi";
import { Employee, GetEmployeePayload } from "types/common";
import { RESPONSE_TYPE } from "@/services/enums/httpEnum.ts";
import { showLoader } from "@/utils/utils";
import { fetchHostCompanies } from "@/services/api/user/user.api";
import { ACTION_MODE } from "@/services/enums/commonEnum";

export default {
  /**
   * Attempt to sign in a user
   *
   * @param {UserSignIn} signInData
   * @param {Function} successCallback
   * @param {Function} errorCallback
   */
  async signInAction(
    this: StoreContext,
    signInData: UserSignIn
  ): Promise<Object> {
    showLoader();
    return signIn(signInData.email, signInData.password)
      .then((res) => {
        localStorageResetAction();
        return res;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        showLoader(false);
      });
  },

  /**
   *
   * @param {this} StoreContext -its contains store context
   */
  async signOutAction(this: StoreContext) {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    try {
      this.$reset();
      await signOut().then(() => {
        loader.changeLoadingStatus({ isLoading: false });
        localStorageResetAction();
        this.$state.isFetchingCognitoInfoExecuting = false;
      });
    } catch (err) {
      this.$reset();
      loader.changeLoadingStatus({ isLoading: false });
      this.$state.isFetchingCognitoInfoExecuting = false;
      showToast("error", (err as GeneralError).message, 1800, "top-right");
    }
  },

  /**
   * fetching user info after login
   */
  async fetchingUserInfo(this: StoreContext): Promise<object> {
    showLoader();
    return getUser()
      .then((result) => {
        this.$state.data = result;
        this.$state.isAuthenticated = true;
        this.$state.isFetchingCognitoInfoExecuting = false;
        return result;
      })
      .catch((error) => {
        this.$state.isAuthenticated = false;
        this.$state.isFetchingCognitoInfoExecuting = false;

        throw new Error(error?.message ? error?.message : error);
      })
      .finally(() => {
        showLoader(false);
      });
  },

  /**
   * Add/Edit/Get user action
   */
  async userManagement(
    this: StoreContext,
    payload: Employee | GetEmployeePayload,
    mode: number,
    virtualScroll: boolean = false
  ): Promise<EmployeeDataT | EmployeeT> {
    return userManagement(payload, mode)
      .then((result) => {
        if (mode === ACTION_MODE.GET_LIST) {
          const res = result.data.data as EmployeeDataT;
          const requestPayload = payload as GetEmployeePayload;
          this.$state.userListResponse.count = res.count;

          if (virtualScroll && requestPayload.skip > 0) {
            this.$state.userListResponse.results = [
              ...this.$state.userListResponse.results,
              ...res.results,
            ];
          } else {
            this.$state.userListResponse.results = res.results;
          }
        }
        return result.data.data;
      })
      .catch((error) => {
        throw error.response.data.message;
      });
  },

  /*
   * Company user details
   * */
  async getCompanyUserDetails(
    this: StoreContext
  ): Promise<CompanyUserDetailsT> {
    try {
      const { data: response } = await fetchCompanyUserDetails();
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      this.$state.companyUserDetails = response.data;
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async fetchHostCompanyData(
    this: StoreContext,
    payload: HostCompanyFilter
  ): Promise<HostCompaniesData> {
    try {
      const { data: response } = await fetchHostCompanies(payload);
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      this.$state.hostCompanyData.count = response.data.count;

      if (payload.skip === 0) {
        this.$state.hostCompanyData.results = response.data.results;
      } else {
        this.$state.hostCompanyData.results = [
          ...this.$state.hostCompanyData.results,
          ...response.data.results,
        ];
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
