import { defineStore } from "pinia";
import actions from "./auth.actions";
import state from "./auth.state";
import getters from "./auth.getters";
/*
 * extracting store in one file
 */
const useAuthStore = defineStore("auth", {
  state,
  actions,
  persist: true,
  getters,
});

export default useAuthStore;
