// import { EtagService } from "@/core/http/EtagService";
import { getAuthJWTToken } from "@/services/auth/auth.service.ts";
import { handleErrorResponse } from "@/core/http/axios/handleResponse";
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { showLoader, userCompanyId } from "@/utils/utils";
/*
 * creating an axios instance
 */
export const useAxios = axios.create({
  baseURL: import.meta.env.VITE_APP_ROOT_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

/**
 * Interceptor handler for Axios requests,
 *
 *
 * @param   {AxiosRequestConfig} config - request config
 * @returns {AxiosRequestConfig}        - request config
 */
const onRequest = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  showLoader();
  try {
    const res = await getAuthJWTToken();
    config.headers.Authorization = `Bearer ${res}`;
  } catch (error) {
    // Handle token fetch error, if necessary
    console.error("Error fetching token:", error);
  }
  const isAppless = config.url?.includes("/appless/");
  // Check if it's a GET request
  if (config.method && config.method.toUpperCase() === "GET" && !isAppless) {
    config.params = {
      ...config.params,
      company_id: userCompanyId(),
    };
  } else if (
    // Check if it's a POST or PATCH or PUT request
    config.method &&
    ["POST", "PATCH", "PUT"].includes(config.method.toUpperCase()) &&
    !isAppless
  ) {
    config.data = {
      ...config.data,
      company_id: userCompanyId(),
    };
  }
  return config;
};

/**
 * Handles any http request error
 *
 * @param   {AxiosError} error    - axios error
 * @returns {Promise<AxiosError>} - returns a promise of axios error
 */
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

/**
 * Interceptor handler for successfully Axios responses
 *
 * @param   {AxiosResponse} response  - incoming axios response
 * @returns {AxiosResponse}           - axios response (pass thru)
 */
const onResponse = (response: AxiosResponse): AxiosResponse => {
  /*
   * storing etag header
   * We dont have this functionality in our BE, In future may be we will work.
   */
  // EtagService.storeEtag(response);
  return response;
};

/**
 * Interceptor handler for failed Axios responses,
 * as defined by `validateStatus` in axios config
 * (see [documentation](https://axios-http.com/docs/handling_errors))
 *
 * @param   {AxiosError} error    - axios error
 * @returns {Promise<AxiosError>} - returns a promise of type axios error
 */
const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  /*
   * handling error response
   */
  handleErrorResponse(error);
  return Promise.reject(error);
};

/**
 * Axios request interceptor, delegating logic to dedicated handlers
 */
useAxios.interceptors.request.use(onRequest, onRequestError);

/**
 * Axios response interceptor, delegating logic to dedicated handlers
 */
useAxios.interceptors.response.use(onResponse, onResponseError);
