import { defineStore } from "pinia";
import state from "./trip.state";
import getters from "./trip.getters";
import actions from "./trip.actions";

const useTripStore = defineStore("trip", {
  state,
  getters,
  actions,
});

export default useTripStore;
