import { Auth, Amplify, Storage } from "aws-amplify";
import { showToast } from "../toast/toast.service";
import { ForgotPassword } from "@/components/modules/auth/types";

/**
 *
 * limitation @link https://github.com/aws-amplify/amplify-flutter/issues/1902
 */

export const initAmplify = () => {
  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: import.meta.env.VITE_APP_AUTH_IDENTITY_POOL_ID,

      // REQUIRED - Amazon Cognito Region
      region: import.meta.env.VITE_APP_AUTH_REGION,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: import.meta.env.VITE_APP_AUTH_IDENTITY_POOL_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: import.meta.env.VITE_APP_AUTH_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: import.meta.env.VITE_APP_AUTH_CLIENT_ID,
    },

    Storage: {
      AWSS3: {
        bucket: import.meta.env.VITE_APP_S3_BUCKET,
        // level: "public",
        region: import.meta.env.VITE_APP_AUTH_REGION,
        customPrefix: {
          public: "",
        },
      },
    },
  });

  Auth.configure();
  Storage.configure();
};

export const signIn = async (email: string, password: string) => {
  return await Auth.signIn(email, password);
};
export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    showToast("error", "Can't sign out right now!", 1000);
  }
};
export const getUser = async () => {
  const user = await Auth.currentUserInfo();
  if (user && Object.keys(user).length) {
    return user;
  } else {
    throw new Error("User not found");
  }
};
export const getAuthenticatedUser = async () => {
  return Auth.currentAuthenticatedUser();
};
export const getAuthJWTToken = async () => {
  const session = await Auth.currentSession();

  return session.getAccessToken().getJwtToken();
};
export const refreshToken = async () => {
  return Auth.currentSession();
};
export const changePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.changePassword(user, oldPassword, newPassword);
};

export const forgotPasswordSubmit = async (payload: ForgotPassword) => {
  return await Auth.forgotPasswordSubmit(
    payload.username,
    payload.code,
    payload.new_password
  );
};

export const forgotPassword = async (userName: string) => {
  return await Auth.forgotPassword(userName)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
};
