import { State, StoreContext } from "./types";

export default {
  /**
   * Change loading status action
   * @param payload
   */
  changeLoadingStatus(this: StoreContext, payload: State) {
    this.$state = {
      ...this.$state,
      isLoading: payload.isLoading,
    };
  },
};
