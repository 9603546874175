export enum localStorageKeys {
  date_query = "date_query",
  selected_org_filter = "network-selected_org-filter",
  filter_status = "filter_status",
  auth = "auth",
}

export const deleteFromLocalStorage = (key) => {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
};

/**
 * Retrieves an item from local storage.
 * @param {string} key - The key to retrieve.
 * @returns {string|null} - The value associated with the key, or null if it doesn't exist.
 */
export const localStorageGetItem = (key: string): string | null => {
  return localStorage.getItem(key);
};

/**
 * Saves an item to local storage.
 * @param {string} key - The key to set.
 * @param {string} value - The value to associate with the key.
 */
export const localStorageSetItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const localStorageRemoveItem = (key: string) => {
  if (localStorageGetItem(key)) {
    localStorage.removeItem(key);
  }
};

export const localStorageResetAction = () => {
  localStorageRemoveItem(localStorageKeys.date_query);
  localStorageRemoveItem(localStorageKeys.filter_status);
  localStorageRemoveItem(localStorageKeys.selected_org_filter);
  localStorageRemoveItem(localStorageKeys.auth);
};
