import { createApp, h } from "vue";
import "./assets/scss/index.scss";
import App from "./App.vue";
import CommonComponents from "./components/common/";
import Toast, { PluginOptions } from "vue-toastification";
import { ToastOptionsAndRequiredContent } from "vue-toastification/dist/types/types";
import router from "./router";
import { i18n } from "./locales";
import { createPinia } from "pinia";
import { worker } from "./mocks/browser";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "vue-toastification/dist/index.css";
import usePrimeVueComponents from "./composables/usePrimeVueComponents";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { initAmplify } from "./services/auth/auth.service";
import "shepherd.js/dist/css/shepherd.css";
import Colada, { PiniaColadaPlugin } from "colada-plugin";
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaColadaPlugin);
const app = createApp({
  setup() {
    initAmplify(); //Initiating AWS amplify.
  },
  render: () => h(App),
});
app.use(Colada);
CommonComponents.register(app);
usePrimeVueComponents(app);
app.directive("tooltip", Tooltip);

if (import.meta.env.MODE === "mock") {
  worker.start();
}

const filterBeforeCreate = (
  toast: ToastOptionsAndRequiredContent,
  toasts: ToastOptionsAndRequiredContent[]
) => {
  if (toasts.filter((t) => t.type === toast.type).length !== 0) {
    // Returning false discards the toast
    return false;
  }
  // You can modify the toast if you want
  return toast;
};

const options: PluginOptions = {
  // You can set your default options here
  timeout: 1000,
  transition: "enter",
  filterBeforeCreate: filterBeforeCreate,
};
/*
 * initially used for http response(success and error) handling
 */
app.use(PrimeVue);
app.use(Toast, options);
app.use(i18n);
app.use(pinia);
app.use(router);
app.mount("#app");
