import { defineStore } from "pinia";
import state from "./notification.state";
import actions from "./notification.actions";
import getters from "./notification.getters";

const useNotificationStore = defineStore("notification", {
  state,
  actions,
  getters,
});

export default useNotificationStore;
