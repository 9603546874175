/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { response, ResponseTransformer, MockedResponse, context } from "msw";

// Value for `company-gmt-offset` (minutes diff from GMT)
// Set it to `new Date().getTimezoneOffset() + ""` for same as client
// Example : -60 means UTC + 1hr
const mock_company_gmt_offset = "-60"; //new Date().getTimezoneOffset() + ""; // "-120";
// new Date().toLocaleString("nb-NO", {timeZone: "Europe/Oslo"})

/**
 * A custom response composition chain, to reuse
 * standard response configurations according to our needs.
 * See [MSW docs](https://mswjs.io/docs/api/response#custom-response-composition).
 *
 * @param  {...ResponseTransformer}   transformers    - "pass-thru" transformers
 * @returns {MockedResponse}                          - decorated response for reuse
 */
export function customResponse(...transformers) {
  const mock_response_now = new Date();

  return response(
    ...transformers,
    context.set({
      /**
       * Company's current time offset from GMT / UTC (adjusted for DST), according to
       * [getTimezoneOffset](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset)
       */
      "company-gmt-offset": mock_company_gmt_offset,
      /** custom header: Unix timestamp (in seconds) */
      "utc-ts": Math.round(mock_response_now.getTime() / 1000),
      /**
       * Company's time zone, usong Oslo for testing - see
       * [current time in Oslo](https://www.zeitverschiebung.net/en/timezone/europe--oslo)
       */
      "company-tz": "Europe/Oslo",
      Date: mock_response_now.toUTCString(), // Standard response header
    }),
    // Adding a delay for testing of latency - can be changed for testing
    context.delay(700)
  );
}
