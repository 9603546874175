import { useAxios } from "@/services/axios";
import { HTTP_USER } from "@/services/enums/httpEnum";
import { HostCompanyResponse } from "@/stores/user/types";
import { AxiosResponse } from "axios";

const fetchHostCompanies = (
  payload
): Promise<AxiosResponse<HostCompanyResponse>> => {
  return useAxios.get(HTTP_USER.HOST_COMPANIES, {
    params: payload,
    headers: { "X-Api-Key": import.meta.env.VITE_APP_API_KEY },
  });
};

export { fetchHostCompanies };
