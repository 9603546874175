import { rest } from "msw";
import { customResponse } from "../../../mocks/customResponse";
import { HTTP_AUTH } from "../../enums/httpEnum";

export const mockAuthHandlers = [
  /**
   * Handler for fetch mock mock verify user
   */
  rest.get(HTTP_AUTH.VERIFY_INVITE_LINK, (_req, _, ctx) => {
    console.log(_req.params);
    return customResponse(
      //ctx.set("company-time", new Date().getTime() + ""),
      ctx.status(200),
      ctx.json({
        first_name: "John",
        last_name: "doe",
        organization: "BS",
        phone_number: "+8801111111111",
        email: "bs-cp@yopmail.com",
        root_org: "UNKNOWN",
      })
    );
  }),
];
