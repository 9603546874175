import { AxiosError } from "axios";
import { showToast } from "@/services/toast/toast.service";

/**
 * Handler to show toast for server error
 *
 * @param {AxiosError} error - Axios error object
 */
export const handleErrorResponse = (error: AxiosError): void => {
  const { response, config } = error;
  const headers = config?.headers;
  /*
    data - its depend on server response on API, data property are 'code' and 'type', it will be replaced as BE gives
  */
  const data = response?.data as { code: number; type: string };

  if (data?.code && !headers?.showToast) {
    return;
  } else if (!data?.code && headers?.showToast) {
    showToast(
      "error",
      "An error has been encountered; kindly reach out to our support team for further assistance."
    );
    return;
  } else {
    // Handling responses.
    switch (data?.code) {
      /*
       * If user has no permission to visit an authorized url user
       * will be redirected to unautorized view
       */
      case 401:
        showToast(
          "error",
          "Unauthorized - An error has been encountered; kindly reach out to our support team for further assistance."
        );
        break;
      /*
       * If user is forbidden to perform any task.
       */
      case 403:
        showToast(
          "error",
          "Forbidden - An error has been encountered; kindly reach out to our support team for further assistance."
        );
        break;
      /*
       * If the content is not found.
       */
      case 404:
        showToast(
          "error",
          "Not found - An error has been encountered; kindly reach out to our support team for further assistance."
        );
        break;
      /*
       * If error comes from server.
       */
      case 500:
        showToast(
          "error",
          "Server error - An error has been encountered; kindly reach out to our support team for further assistance."
        );
        break;
      default:
        showToast(
          "error",
          "An error has been encountered; kindly reach out to our support team for further assistance."
        );
        return;
    }
  }
};
