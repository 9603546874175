export enum RESPONSE_TYPE {
  ERROR = "error",
  SUCCESS = "success",
}

export enum HTTP_CUSTOMER_PORTAL {
  FETCH_TRIP_LIST = "/customer-portal/trips/",
}
export enum HTTP_AUTH {
  VERIFY_INVITE_LINK = "/verify",
  CREATE_PROFILE = "/create-profile",
  ACCEPT_INVITE_AND_CREATE_USER = "/accept-invitation",
  INVITATION_ACTION = "/invitation-action",
}

export enum HTTP_USER {
  USER_MANAGEMENT = "/user-management",
  COMPANY_USER_DETAILS = "/company-user-details",
  HOST_COMPANIES = "/partner-host-companies",
}

export enum HTTP_VEHICLE {
  VEHICLES = "/vehicles",
}

export enum HTTP_TRIP {
  TRIP_LIST = "/trip-list",
  REQUESTED_TRIPS = "/requested-trips",
  CLOSED_TRIPS = "/finished-trips",
  ASSIGN_TRIP_VEHICLE = "/vehicle-assignment",
  TRIP_DETAILS = "/trip-details",
  APP_LESS_TRIP_DETAILS = "/appless/trip-details",
  SEND_APP_LESS_OTP = "/appless/send-otp",
  ASSIGN_TRIP_DRIVER = "/driver-assignment",
  ADD_EXPENSES = "/claim-management/add-expense",
  TRIP_ACTIONS = "/trip-actions",
  TRIP_ACTION_INVOICE_EXPORT = "/trip-actions/export-invoice-trips",
  APP_LESS_TRIP_ACTIONS = "/appless/trip-actions",
  SUBMIT_CLAIM = "/claim-management/claim-actions",
}

export enum HTTP_NOTIFICATION {
  NOTIFICATION_SETTINGS = "/user-management/notification",
}
