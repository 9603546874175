import { State } from "./types";
/*
 * define state here
 */
export default (): State => ({
  verifyUserQuery: {
    user_id: "",
    company_affiliation_id: "",
  },
  verifyAPIExecuting: true,
  verifyUserResponse: {
    admin_user: {
      user_id: "",
      email: "",
      phone: "",
      user_status: "",
      user_profile: "",
    },
    company_affiliation_info: {
      _id: "",
      host_company: {
        id: "",
        name: "",
        country_code: "",
        org_number: "",
        type: "",
        domain: null,
      },
      target_company: {
        id: "",
        name: "",
        country_code: "",
        org_number: "",
        type: "",
        domain: null,
      },
      status: "",
      terms_and_conditions: "",
    },
    verification_type: "",
  },
  resetPassword: {
    email: "",
  },
});
