import {
  CreateProfileT,
  ForgotPassword,
  StoreContext,
  UserVerifyResponse,
  verifyUserT,
  VerifyAffiliationT,
} from "./types";
import {
  fetchVerifyUser,
  acceptInviteAndCreateUser,
  verifyAffiliation,
} from "@/services/api/auth/authApi.ts";
import { RESPONSE_TYPE } from "@/services/enums/httpEnum.ts";
import {
  forgotPassword,
  forgotPasswordSubmit,
} from "@/services/auth/auth.service";
import { showLoader } from "@/utils/utils";

export default {
  /**
   * Change loading status action
   * @param payload
   */
  /**
   * Verifies the user by sending a verification code and updating the user profile.
   * @param payload - The payload containing the user ID and confirmation code.
   */
  async verifyUser(
    this: StoreContext,
    payload: verifyUserT
  ): Promise<UserVerifyResponse> {
    this.$state.verifyUserQuery = payload;
    return fetchVerifyUser(payload)
      .then((res) => {
        const response = res.data;
        if (response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        this.$state.verifyUserResponse = response.data;
        return response.data;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        this.$state.verifyAPIExecuting = false;
      });
  },

  /**
   * send otp to verify a user attribute
   *
   * @param {StoreContext} this - the state
   * @param {ForgotPassword} payload - payload for forgot password
   */
  /**
   * Handles the submission of a forgot password request.
   *
   * @param payload - The payload containing the necessary information for the forgot password request.
   * @returns A Promise that resolves to a string representing the result of the forgot password request.
   */
  async handleForgotPasswordSubmit(
    this: StoreContext,
    payload: ForgotPassword
  ): Promise<string> {
    showLoader();
    return forgotPasswordSubmit(payload)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });
  },

  /**
   * send otp to verify a user attribute
   *
   * @param {StoreContext} this - the state
   * @param {string} userName - the username of users
   */
  /**
   * Handles the forgot password code for a given user.
   * @param userName - The username of the user.
   * @returns A Promise that resolves to an object.
   */
  async handleForgotPasswordCode(
    this: StoreContext,
    userName: string
  ): Promise<object> {
    showLoader();
    return forgotPassword(userName)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },

  /**
   * Creates a user profile from an invitation.
   * @param payload - The payload containing the necessary data to create the profile.
   * @returns A Promise that resolves to an object representing the created profile.
   * @throws If there is an API error during the process.
   */
  async createProfileFromInvitation(
    this: StoreContext,
    payload: CreateProfileT
  ): Promise<object> {
    try {
      const res = await acceptInviteAndCreateUser(payload);
      const response = res.data;
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  /**
   * Verifies the affiliation by target.
   * @param {VerifyAffiliationT} payload - The payload containing the verification data.
   * @returns {Promise<object>} - A promise that resolves to the response data.
   * @throws {Error} - If there is an API error.
   */
  async verifyAffiliationByTarget(
    this: StoreContext,
    payload: VerifyAffiliationT
  ): Promise<object> {
    try {
      const res = await verifyAffiliation(payload);
      const response = res.data;
      if (response?.status !== RESPONSE_TYPE.SUCCESS) {
        throw Error("API error");
      }
      return response.data;
    } catch (err) {
      throw err;
    }
  },
};
