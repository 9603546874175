import { AxiosResponse } from "axios";
import { useAxios } from "@/services/axios";
import { HTTP_AUTH, HTTP_USER } from "@/services/enums/httpEnum.ts";
import {
  CreateProfileResponse,
  CreateProfileT,
  VerifyAffiliationResponse,
  VerifyAffiliationT,
  VerifyUserResponse,
} from "@/stores/auth/types";
import { Employee, GetEmployeePayload } from "types/common";
import { ACTION_MODE } from "@/services/enums/commonEnum";
import {
  CompanyUserDetailsResponse,
  UserManagementResponse,
} from "@/stores/user/types.ts";

/**
 * Fetches the verification of a user.
 *
 * @param payload - The payload containing user verification data.
 * @returns A Promise that resolves to the response from the server.
 */
const fetchVerifyUser = (
  payload
): Promise<AxiosResponse<VerifyUserResponse>> => {
  return useAxios.post(HTTP_AUTH.VERIFY_INVITE_LINK, payload, {
    headers: { "X-Api-Key": import.meta.env.VITE_APP_API_KEY },
  });
};

/**
 * Accepts an invite and creates a user.
 *
 * @param payload - The payload containing user profile data.
 * @returns A Promise that resolves to the response from the server.
 */
const acceptInviteAndCreateUser = (
  payload: CreateProfileT
): Promise<AxiosResponse<CreateProfileResponse>> => {
  return useAxios.post(HTTP_AUTH.ACCEPT_INVITE_AND_CREATE_USER, payload, {
    headers: { "X-Api-Key": import.meta.env.VITE_APP_API_KEY },
  });
};

/**
 * Verifies the affiliation of a user.
 * @param payload - The payload containing the necessary information for verification.
 * @returns A promise that resolves to an AxiosResponse containing the verification response.
 */
const verifyAffiliation = (
  payload: VerifyAffiliationT
): Promise<AxiosResponse<VerifyAffiliationResponse>> => {
  return useAxios.post(HTTP_AUTH.INVITATION_ACTION, payload, {
    headers: { "X-Api-Key": import.meta.env.VITE_APP_API_KEY },
  });
};

const employeeApi = {
  [ACTION_MODE.GET_LIST]: (params) =>
    useAxios.get(HTTP_USER.USER_MANAGEMENT, {
      params,
    }),
  [ACTION_MODE.GET_SINGLE]: (params) =>
    useAxios.get(HTTP_USER.USER_MANAGEMENT, {
      params,
    }),
  [ACTION_MODE.ADD]: (payload) =>
    useAxios.post(HTTP_USER.USER_MANAGEMENT, payload),
  [ACTION_MODE.EDIT]: (payload) =>
    useAxios.put(HTTP_USER.USER_MANAGEMENT, payload),
};

const userManagement = (
  payload: Employee | GetEmployeePayload,
  mode: number
): Promise<AxiosResponse<UserManagementResponse>> => {
  return employeeApi[mode](payload);
};

/**
 * Get company user info details
 * @returns A Promise that resolves to the response from the server.
 */

const fetchCompanyUserDetails = (): Promise<
  AxiosResponse<CompanyUserDetailsResponse>
> => {
  return useAxios.get(HTTP_USER.COMPANY_USER_DETAILS);
};

export {
  fetchVerifyUser,
  acceptInviteAndCreateUser,
  verifyAffiliation,
  userManagement,
  fetchCompanyUserDetails,
};
